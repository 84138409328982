/* --------------- M E D I A     Q U E R I E S --------------- */

/* general */
@media only screen and (min-width: 1024px) {
    .Header__title {
        word-spacing: 20px;
        letter-spacing: 20px;
    }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1180px) {
    .Header__title {
        word-spacing: 8px;
        letter-spacing: 8px;
    }
}

/* @media only screen and (orientation: portrait) {
    body {
        background-color: lightblue !important;
    }
} */

/* mobil */
/* @media only screen and (max-width: 767px) {
    .Header__title{
        word-spacing: 30px;
        letter-spacing: 30px;
    }
  } */