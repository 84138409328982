#navbar-menu {
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-bottom-color: #aa0787;
    position: sticky;
    top: 0;
    z-index: 4;
}

.dropdown-item {
    color: #464646 !important;
    font-size: .8rem !important;
}

.dropdown-item:hover {
    background-color: #A9387D !important;
    color: white !important;
}

.navbar-nav .nav-item .nav-link:focus,
.navbar-nav .nav-item .nav-link:hover {
    background-color: #e33292 !important;
    color: #ffffff !important;
}

.Navbar__logoITE {
    display: none;
}

/* --------------- M E D I A     Q U E R I E S --------------- */

/* general */
@media only screen and (min-width: 1024px) {
    #navbar-menu .disabled.nav-link {
        color: #999999 !important;
    }

    #navbar-menu .navbar-nav {
        text-align: center;
    }

    .navbar-nav .nav-item .nav-link {
        color: #ffffff;
        font-size: 1.1rem !important;
        background-color: #aa0787;
        border: 1px solid #ffffff;
        border-radius: 15px;
        box-shadow: 3px 3px 10px #464646;
        margin: 2px;
        padding: 10px 30px !important;
    }
}

/* mobil */
@media only screen and (max-width: 767px) {
    .Navbar__logoITE {
        display: inline;
        width: 7rem;
    }

    .navbar-nav .nav-item .nav-link {
        font-size: 1rem !important;
        padding: 5px 0 5px 10px !important;
    }

    .dropdown-item {
        font-size: .9rem !important;
        padding: 0 0 0 10px !important;
    }

    .dropdown-menu {
        background-color: #f3f3f3;
    }
}