body {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", Arial, sans-serif;
  text-align: justify !important;
  font-size: 20px !important;
}

h1, h2, h3, h4, h5 {
  color: #ba007d;
  -webkit-text-stroke: 2px #ba007d;
}

h3, h4, h5 {
  -webkit-text-stroke: 1px #ba007d;
}

li::marker {
  color: #aa0787;
  font-weight: bolder;
  font-size: 20px;
}

.bg-ite {
  background-color: #ba007d !important;
}

.w-10 {
  width: 10% !important;
}

.App__container {
  margin: auto;
}

/* --------------- G A L E R I A     R E S P O N S I V E --------------- */

.Galeria__responsive {
  margin: 1rem auto;
  width: 100%;
  max-width: 1024px;
  column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-gap: 20px;
  column-gap: 20px;
  -moz-row-gap: 20px;
  -webkit-row-gap: 20px;
  row-gap: 20px;
}

.Galeria__responsive-item {
  margin: 0 0 20px 0;
  break-inside: avoid;
  page-break-inside: avoid;
}

/* --------------- M O D A L --------------- */

.modal-header {
  background: #972069 !important;
}

.modal-title {
  color: #ffffff !important;
}

/* --------------- M E D I A     Q U E R I E S --------------- */

/* general */
@media only screen and (min-width: 1024px) {
  .App__container {
    /* border: 2px solid red; */
    width: 75%;
    padding-bottom: 80px;
  }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1180px) {
  .App__container {
    /* border: 2px solid blue; */
    width: 85%;
    padding-bottom: 120px;
  }
}

/* mobil */
@media only screen and (max-width: 767px) {
  .App__container {
    /* border: 1px solid orange; */
    width: 90%;
    padding-bottom: 130px;
    margin-bottom: 20px;
  }
}